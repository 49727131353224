import { FocusEventHandler, useCallback, useEffect, useMemo, useRef, useState } from 'react';

import useSWR from 'swr';
import clsx from 'clsx';
import { useAccount, useReadContract } from 'wagmi';
import { Setting2 } from 'iconic-react';

import { DEFAULT_DECIMALS, useTokenContext } from 'context/token.context';

import { TokenTypes, PairsResponse } from 'constants/constants';
import { showNotification } from 'libs/libs';

interface ITokenInput {
  tokenType: TokenTypes;
  classNames?: string;
  tokenImage?: string;
  abi?: any;
  api: string;
  label: string;
  contractAddress?: `0x${string}`;
  tokenImageUrl?: string;
  setActiveToken?: (token: TokenTypes) => void;
  showImageDropdown?: boolean;
  isSelectable?: boolean;
  selectableOptions?: {
    img: string;
    label: string;
    value: TokenTypes;
  }[];
}

export const FALLBACK_ADDRESS = '0x0000000000000000000000000000000000000000';

const TokenInput = (props: ITokenInput) => {
  const {
    tokenType,
    classNames,
    tokenImage,
    abi,
    api,
    label,
    contractAddress,
    tokenImageUrl,
    setActiveToken = (TokenTypes) => {},
    isSelectable,
    selectableOptions
  } = props;

  const [customTokenAmount, setCustomTokenAmount] = useState<string>('');
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const inputRef = useRef<HTMLInputElement>(null);

  const { address } = useAccount();
  const {
    setActiveTokenPrice,
    activeTokenBalance,
    setActiveTokenBalance,
    setIBurnPrice,
    setPlsPrice,
    setActiveTokenAmount,
    activeTokenPriceInUSD,
    iBurnPriceInUSD,
    activeTokenAmountInteger
  } = useTokenContext();

  useEffect(() => {
    setCustomTokenAmount(Number(activeTokenAmountInteger.toFixed(5)).toString());
  }, [activeTokenAmountInteger]);

  const {
    data: balance,
    isPending,
    refetch
  } = useReadContract({
    address: contractAddress,
    abi: abi || [],
    functionName: 'balanceOf',
    args: [address || FALLBACK_ADDRESS]
  });

  useEffect(() => {
    const interval = setInterval(() => {
      refetch();
    }, 5000);

    return () => clearInterval(interval);
  }, [refetch]);

  useEffect(() => {
    if (tokenType !== TokenTypes.IBURN) {
      setActiveTokenBalance(balance as BigInt);
    }
  }, [balance, tokenType, contractAddress, setActiveTokenBalance]);

  const fetcher = <T,>(...args: Parameters<typeof fetch>) =>
    fetch(...args).then((res) => res.json() as Promise<T>);
  const { data, isLoading, isValidating } = useSWR(api, fetcher);

  useEffect(() => {
    const tokenData: PairsResponse | undefined = data as PairsResponse;
    if (tokenData && typeof tokenData === 'object' && 'pairs' in tokenData) {
      const pairData = tokenData.pairs?.[0] || {};

      (tokenType === TokenTypes.IBURN ? setIBurnPrice : setActiveTokenPrice)(
        pairData?.priceUsd || '0'
      );
    }
  }, [data, setActiveTokenPrice, setIBurnPrice, setPlsPrice, tokenType]);

  const tokenPrice = useMemo(() => {
    return tokenType === TokenTypes.IBURN ? iBurnPriceInUSD : activeTokenPriceInUSD;
  }, [activeTokenPriceInUSD, iBurnPriceInUSD, tokenType]);

  const balanceInteger = useMemo(() => {
    const tokenBalance = tokenType === TokenTypes.IBURN ? balance : activeTokenBalance;
    return parseFloat(tokenBalance?.toString() || '0n') / 10 ** DEFAULT_DECIMALS;
  }, [balance, activeTokenBalance, tokenType]);

  const handleBlur: FocusEventHandler = useCallback(
    (e) => {
      if (!customTokenAmount) {
        setCustomTokenAmount('0');
      }
    },
    [customTokenAmount]
  );

  const handleInputChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const value = parseFloat(event.target.value);

      if (isNaN(value) || value === 0) {
        setCustomTokenAmount(isNaN(value) ? '' : '0');
        setActiveTokenAmount(0n);
      } else {
        setActiveTokenAmount(BigInt(value * 10 ** 9));
      }
    },
    [setActiveTokenAmount]
  );

  const handleClickMax = useCallback(() => {
    setActiveTokenAmount(activeTokenBalance);
    setCustomTokenAmount(activeTokenBalance.toString());
  }, [activeTokenBalance, setActiveTokenAmount]);

  const addTokenToMetamask = useCallback(
    async (
      address: `0x${string}` | undefined,
      symbol: TokenTypes,
      decimals: number,
      image: string | undefined
    ) => {
      try {
        if (window.ethereum) {
          const wasAdded = await window.ethereum.request({
            method: 'wallet_watchAsset',
            params: {
              type: 'ERC20',
              options: {
                address,
                symbol,
                decimals,
                image
              }
            }
          });

          if (wasAdded) {
            showNotification('Token added to Metamask', 'success');
          } else {
            showNotification('Token not added to Metamask', 'error');
          }
        } else {
          showNotification('Please install Metamask extension', 'error');
        }
      } catch (error) {
        showNotification("Can't add token to Metamask", 'error');
      }
    },
    []
  );

  return (
    <div className={clsx('flex w-full flex-col p-2', classNames)}>
      <div className="flex w-full items-center justify-between">
        <label htmlFor={label} className="max-w-90 flex w-full flex-col gap-y-1 text-gray-400">
          <span
            className={clsx('text-sm font-bold text-secondary', {
              'text-theme': tokenType === TokenTypes.IBURN
            })}
          >
            {label}
          </span>
          <input
            name={label}
            type="number"
            ref={inputRef}
            className={clsx('w-full bg-transparent text-3xl font-bold outline-none', {
              'cursor-default': tokenType === TokenTypes.IBURN
            })}
            readOnly={tokenType === TokenTypes.IBURN}
            defaultValue={customTokenAmount}
            value={tokenType === TokenTypes.IBURN ? customTokenAmount : customTokenAmount || '0'}
            onBlur={handleBlur}
            min={0}
            onChange={handleInputChange}
          />
        </label>
        {Boolean(tokenImage) && (
          <div
            className="item-center relative flex h-8 w-auto cursor-pointer gap-x-1 text-gray-500"
            onClick={() => {
              isSelectable
                ? setIsDropdownOpen(!isDropdownOpen)
                : addTokenToMetamask(contractAddress, tokenType, DEFAULT_DECIMALS, tokenImageUrl);
            }}
          >
            <img
              src={tokenImage}
              title="Add token to the Metamask"
              alt="token logo"
              className="h-8 w-8"
            />
            {isSelectable && (
              <div
                className={clsx('transform text-white', {
                  'rotate-180': isDropdownOpen
                })}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-8 w-6"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M19 9l-7 7-7-7"
                  />
                </svg>
              </div>
            )}
            <div
              className={clsx(
                'drop-menu absolute left-0 top-full z-10 mt-1 w-48 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none',
                {
                  hidden: !isDropdownOpen
                }
              )}
            >
              {(selectableOptions || []).map((option) => (
                <div
                  key={option.label}
                  className="flex items-center gap-x-2 rounded-md px-2 py-1 hover:bg-gray-100"
                  onClick={() => setActiveToken(option.value)}
                >
                  <img src={option.img} alt={option.label} className="h-8 w-8" />
                  <span>{option.label}</span>
                </div>
              ))}
            </div>
          </div>
        )}
      </div>
      <div className="flex items-center justify-between">
        <div className="text-sm font-semibold text-gray-500">
          {isLoading || isValidating ? (
            <Setting2 size={16} variant="Outline" className="animate-spin" />
          ) : (
            <div>{`$${Number(tokenPrice.toFixed(3)).toLocaleString('en')}`}</div>
          )}
        </div>
        <div className="flex items-center gap-x-2 text-gray-500">
          {isPending ? (
            <Setting2 size={16} variant="Outline" className="animate-spin" />
          ) : (
            <span className="text-sm font-semibold">Balance {balanceInteger.toFixed(1)}</span>
          )}
          {tokenType !== TokenTypes.IBURN && (
            <button
              type="button"
              className="rounded bg-secondary px-1 py-0.5 text-xs font-semibold text-gray-800"
              onClick={handleClickMax}
            >
              Max
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

export default TokenInput;
