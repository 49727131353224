import { createContext, ReactNode, useContext, useState, useMemo } from 'react';

interface ITokenContext {
  activeTokenAmount: BigInt;
  setActiveTokenAmount: (amount: BigInt) => void;
  activeTokenBalance: BigInt;
  setActiveTokenBalance: (amount: BigInt) => void;
  plsPrice: string;
  setPlsPrice: (price: string) => void;
  activeTokenPrice: string;
  setActiveTokenPrice: (price: string) => void;
  iBurnPrice: string;
  setIBurnPrice: (price: string) => void;
  activeTokenPriceInUSD: number;
  iBurnPriceInUSD: number;
  plsPriceInUSD: number;
  activeTokenAmountInteger: number;
  plsAmountInteger: number;
}

export const TokenContext = createContext<ITokenContext>({} as ITokenContext);

export const DEFAULT_DECIMALS = 9;

interface ITokenContextProvider {
  children: ReactNode;
}

export const TokenContextProvider = (props: ITokenContextProvider) => {
  const { children } = props;

  const [activeTokenAmount, setActiveTokenAmount] = useState<BigInt>(0n);
  const [activeTokenBalance, setActiveTokenBalance] = useState<BigInt>(0n);
  const [plsPrice, setPlsPrice] = useState<string>('0');
  const [activeTokenPrice, setActiveTokenPrice] = useState<string>('0');
  const [iBurnPrice, setIBurnPrice] = useState<string>('0');

  const activeTokenAmountInteger = useMemo(
    () => parseFloat(activeTokenAmount.toString()) / 10 ** DEFAULT_DECIMALS,
    [activeTokenAmount]
  );
  const plsAmountInteger = useMemo(
    () => activeTokenAmountInteger * 1500,
    [activeTokenAmountInteger]
  );

  const activeTokenPriceInUSD = useMemo(
    () =>
      parseFloat(activeTokenPrice) *
      (parseFloat(activeTokenAmount.toString()) / 10 ** DEFAULT_DECIMALS),
    [activeTokenAmount, activeTokenPrice]
  );
  const iBurnPriceInUSD = useMemo(
    () =>
      parseFloat(iBurnPrice) * (parseFloat(activeTokenAmount.toString()) / 10 ** DEFAULT_DECIMALS),
    [activeTokenAmount, iBurnPrice]
  );
  const plsPriceInUSD = useMemo(
    () => parseFloat(plsPrice) * plsAmountInteger,
    [plsAmountInteger, plsPrice]
  );

  const value: ITokenContext = useMemo(
    () => ({
      activeTokenAmount,
      setActiveTokenAmount,
      activeTokenBalance,
      setActiveTokenBalance,
      plsPrice,
      setPlsPrice,
      activeTokenPrice,
      setActiveTokenPrice,
      iBurnPrice,
      setIBurnPrice,
      activeTokenPriceInUSD,
      iBurnPriceInUSD,
      plsPriceInUSD,
      activeTokenAmountInteger,
      plsAmountInteger
    }),
    [
      activeTokenAmount,
      activeTokenAmountInteger,
      activeTokenBalance,
      activeTokenPrice,
      activeTokenPriceInUSD,
      iBurnPrice,
      iBurnPriceInUSD,
      plsAmountInteger,
      plsPrice,
      plsPriceInUSD
    ]
  );

  return <TokenContext.Provider value={value}>{children}</TokenContext.Provider>;
};

export const useTokenContext = () => useContext(TokenContext);
