import { useCallback, useEffect, useMemo, useState } from 'react';

import { useAccount, useReadContract, useWaitForTransactionReceipt, useWriteContract } from 'wagmi';
import { Setting2 } from 'iconic-react';

import { track } from '@vercel/analytics';

import { DEFAULT_DECIMALS, useTokenContext } from 'context/token.context';

import ConnectWallet from 'components/ConnectWallet';
import TokenInput, { FALLBACK_ADDRESS } from 'components/TokenInput';
import PriceDisplay from 'components/PriceDisplay';

import { eIcosaAbi, iBurnAbi, icosaAbi } from 'constants/abi';
import { TokenTypes } from 'constants/constants';

import IcosaImg from 'assets/images/Icosa.png';
import eIcosaImg from 'assets/images/eIcosa.png';
import iBurnImg from 'assets/images/iBurn_dark.png';
import plsImg from 'assets/images/pls.webp';

import { showNotification } from 'libs/libs';

const MinterComponent = () => {
  const { address } = useAccount();

  const { plsAmountInteger, activeTokenAmount, activeTokenBalance } = useTokenContext();
  const { data: hash, writeContract, status, error } = useWriteContract();

  const [activeToken, setActiveToken] = useState(TokenTypes.ICOSA);

  const [isMintable, setIsMintable] = useState(false);

  const activeTokenAddress = useMemo(
    () =>
      (activeToken === TokenTypes.ICOSA
        ? process.env.REACT_APP_ICOSA_CONTRACT_ADDRESS
        : process.env.REACT_APP_EICOSA_CONTRACT_ADDRESS) as `0x${string}`,
    [activeToken]
  );

  const activeTokenAbi = useMemo(
    () => (activeToken === TokenTypes.ICOSA ? icosaAbi : eIcosaAbi) || [],
    [activeToken]
  );

  const { data: tokenAllowance, refetch } = useReadContract({
    address: activeTokenAddress,
    abi: activeTokenAbi,
    functionName: 'allowance',
    args: [
      address || FALLBACK_ADDRESS,
      process.env.REACT_APP_IBURN_CONTRACT_ADDRESS as `0x${string}`
    ]
  });

  useEffect(() => {
    const interval = setInterval(() => {
      refetch();
    }, 5000);

    return () => clearInterval(interval);
  }, [activeToken, refetch]);

  const { isLoading } = useWaitForTransactionReceipt({ hash });

  useEffect(() => {
    if (error) {
      showNotification((error as any)?.shortMessage || 'Something went wrong', 'error');
    }
  }, [error]);

  useEffect(() => {
    if (isLoading === false && status === 'success') {
      showNotification('Transaction successful', 'success');
    }
  }, [isLoading, status]);

  const needAllowance = useMemo(() => {
    const tokenAllowanceBigInt: BigInt = (tokenAllowance as unknown as BigInt) || 0n;
    return parseInt(activeTokenAmount?.toString()) > parseInt(tokenAllowanceBigInt?.toString());
  }, [tokenAllowance, activeTokenAmount]);

  useEffect(() => {
    if (!needAllowance && activeTokenAmount !== 0n) {
      setIsMintable(true);
    } else {
      setIsMintable(false);
    }
  }, [activeTokenAmount, needAllowance]);

  const handleApprove = useCallback(async () => {
    if (activeTokenAmount > activeTokenBalance) {
      showNotification('Insufficient Token balance', 'error');
      return;
    }

    writeContract({
      address: activeTokenAddress,
      abi: activeTokenAbi,
      functionName: 'approve',
      args: [
        process.env.REACT_APP_IBURN_CONTRACT_ADDRESS as `0x${string}`,
        BigInt(activeTokenAmount.toString())
      ]
    });
  }, [activeTokenAmount, activeTokenBalance, writeContract, activeTokenAddress, activeTokenAbi]);

  const handleMint = useCallback(async () => {
    if (activeTokenAmount > activeTokenBalance) {
      showNotification('Insufficient Token balance', 'error');
      return;
    }

    writeContract({
      address: process.env.REACT_APP_IBURN_CONTRACT_ADDRESS as `0x${string}`,
      abi: iBurnAbi || [],
      functionName: 'mint',
      args: [
        activeToken === TokenTypes.ICOSA
          ? process.env.REACT_APP_ICOSA_CONTRACT_ADDRESS
          : process.env.REACT_APP_EICOSA_CONTRACT_ADDRESS,
        activeTokenAmount
      ],
      value: BigInt(1_500) * BigInt(activeTokenAmount.toString()) * BigInt(10 ** DEFAULT_DECIMALS)
    });
  }, [activeTokenAmount, activeTokenBalance, writeContract, activeToken]);

  const handleButtonClick = useCallback(() => {
    if (status === 'pending' || isLoading) return;
    if (needAllowance) {
      handleApprove();
      track('approve', { token: activeToken, amount: activeTokenAmount.toString() });
      return;
    }
    if (isMintable) {
      handleMint();
      track('mint', { token: activeToken, amount: activeTokenAmount.toString() });
      return;
    }
  }, [
    activeToken,
    activeTokenAmount,
    handleApprove,
    handleMint,
    isLoading,
    isMintable,
    needAllowance,
    status
  ]);

  const handleSetActiveToken = useCallback((tokenType: TokenTypes) => {
    setActiveToken(tokenType);
  }, []);

  return (
    <div className="flex w-full flex-col gap-y-4 rounded-xl border-2 border-theme bg-gray-700/20 px-4 py-6 shadow-2xl md:w-130">
      <TokenInput
        tokenType={TokenTypes.ICOSA}
        classNames="rounded-md border-2 border-secondary shadow-lg"
        tokenImage={activeToken === TokenTypes.ICOSA ? IcosaImg : eIcosaImg}
        abi={icosaAbi}
        api={`https://api.dexscreener.com/latest/dex/pairs/pulsechain/${activeToken === TokenTypes.ICOSA ? process.env.REACT_APP_ICOSA_PAIR_ADDRESS : process.env.REACT_APP_EICOSA_PAIR_ADDRESS}`}
        label={activeToken === TokenTypes.ICOSA ? 'Send Icosa' : 'Send eIcosa'}
        contractAddress={activeTokenAddress}
        setActiveToken={handleSetActiveToken}
        isSelectable
        selectableOptions={[
          { value: TokenTypes.ICOSA, label: 'Icosa', img: IcosaImg },
          { value: TokenTypes.EICOSA, label: 'eIcosa', img: eIcosaImg }
        ]}
      />
      <TokenInput
        tokenType={TokenTypes.IBURN}
        classNames="rounded-md border-2 border-theme shadow-lg"
        tokenImage={iBurnImg}
        abi={iBurnAbi}
        api={`https://api.dexscreener.com/latest/dex/pairs/pulsechain/${process.env.REACT_APP_IBURN_PAIR_ADDRESS}`}
        label="Mint iBurn"
        contractAddress={process.env.REACT_APP_IBURN_CONTRACT_ADDRESS as `0x${string}`}
        tokenImageUrl={process.env.REACT_APP_SITE_URL + '/iBurn_dark.png'}
      />
      <div className="flex w-full flex-col gap-y-2 rounded-md border-2 border-gray-500 p-2">
        <div className="flex justify-between border-b border-gray-600 py-2 font-bold text-gray-500">
          <span>Protocol Fee:</span>
          <div className="item-center flex gap-x-2">
            <img src={plsImg} alt="PLS token" className="h-6 w-6" />
            <span className="pt-0.25">
              {Number(plsAmountInteger.toFixed(1)).toLocaleString('en')} PLS
            </span>
          </div>
        </div>
        <div className="flex flex-col gap-y-1">
          <PriceDisplay
            title="PLS"
            api={`https://api.dexscreener.com/latest/dex/pairs/pulsechain/${process.env.REACT_APP_WPLS_PAIR_ADDRESS}`}
          />
        </div>
      </div>
      {address ? (
        <button
          type="button"
          className="flex w-full items-center justify-center gap-x-2 rounded bg-secondary px-4 py-1 font-semibold text-white"
          onClick={handleButtonClick}
        >
          {status === 'pending' || isLoading ? (
            <Setting2 size={24} variant="Outline" className="animate-spin" />
          ) : needAllowance ? (
            'Approve'
          ) : isMintable ? (
            'Mint'
          ) : (
            'Enter Amount'
          )}
        </button>
      ) : (
        <ConnectWallet />
      )}
    </div>
  );
};

export default MinterComponent;
