export enum TokenTypes {
  GIFF = 'GIFF',
  PGIFF = 'pGIFF',
  ICOSA = 'ICSA',
  EICOSA = 'EICOSA',
  IBURN = 'iBurn'
}

export type PairsResponse = {
  schemaVersion: string;
  pairs: any[] | null;
};
