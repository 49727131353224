import { BrowserRouter as Router } from 'react-router-dom';

import { Helmet } from 'react-helmet';
import { Bounce, ToastContainer } from 'react-toastify';

import { Analytics } from '@vercel/analytics/react';

import { WagmiProvider, http, createConfig } from 'wagmi';
import { pulsechain, pulsechainV4 } from 'wagmi/chains';
import { walletConnect } from 'wagmi/connectors';

import { QueryClient, QueryClientProvider } from '@tanstack/react-query';

import Header from 'components/Header';
import Footer from 'components/Footer';
import Minter from 'components/Minter';
import { TokenContextProvider } from 'context/token.context';

import iBurnHeader from 'assets/images/iburn_Header_NoStroke_PNG.png';

const config = createConfig({
  chains: [pulsechain],
  connectors: [
    walletConnect({
      projectId: process.env.REACT_APP_WALLET_CONNECT_ID || '',
      qrModalOptions: {
        themeMode: 'dark'
      }
    })
  ],
  transports: {
    [pulsechain.id]: http('https://rpc.pulsechain.com'),
    [pulsechainV4.id]: http('https://rpc.v4.testnet.pulsechain.com')
  }
});

const queryClient = new QueryClient();

const AppContainer = () => {
  return (
    <>
      <Helmet>
        <title>iBURN</title>
      </Helmet>
      <Router>
        <WagmiProvider config={config}>
          <QueryClientProvider client={queryClient}>
            <Header />
            <main className="flex h-full flex-col items-center justify-center gap-y-10 px-4 md:px-6 lg:px-10">
              <TokenContextProvider>
                <a title="Header-icon" href="/" className="flex justify-center gap-x-4">
                  <img src={iBurnHeader} alt="header icon" className="w-full md:w-130" />
                </a>
                <Minter />
              </TokenContextProvider>
            </main>
            <Footer />
            <div id="app-modal-portal" className="relative"></div>
          </QueryClientProvider>
        </WagmiProvider>
        <Analytics />
      </Router>
      <ToastContainer
        position="bottom-right"
        autoClose={3000}
        newestOnTop
        closeOnClick
        pauseOnHover
        pauseOnFocusLoss
        draggable
        theme="colored"
        transition={Bounce}
        bodyClassName=""
        stacked
      />
    </>
  );
};

export default AppContainer;
