import { ReactComponent as Telegram } from 'assets/svgs/telegram.svg';
import { ReactComponent as Twitter } from 'assets/svgs/twitter-x.svg';

import iBurnImg from 'assets/images/iBurn_light.png';

const Footer = () => {
  const handleCopyClick = async (event: any, url: string) => {
    event.preventDefault(); // Prevent the default anchor behavior

    try {
      await navigator.clipboard.writeText(url);
      alert('URL copied to clipboard!'); // Show some feedback
    } catch (err) {
      console.error('Failed to copy:', err);
    }
  };
  return (
    <footer data-testid="footer" className="flex w-full flex-col items-center gap-y-3 pb-4 pt-8">
      <a title="footer-icon" href="/">
        <img src={iBurnImg} alt="footer icon" className="h-10 w-10" />
      </a>
      <div className="flex flex-col items-center gap-y-2">
        <div className="flex gap-x-2 text-gray-400">
          <a
            title="footer-social-twitter"
            href={process.env.REACT_APP_TWITTER_URL}
            rel="noreferrer noopener"
            target="_blank"
            className="h-8 w-8"
          >
            <Twitter className="h-full w-full" />
          </a>
          <a
            title="footer-social-telegram"
            href={process.env.REACT_APP_TELEGRAM_URL}
            rel="noreferrer noopener"
            target="_blank"
            className="h-8 w-8"
          >
            <Telegram className="h-full w-full" />
          </a>
        </div>
        <a
          href={`https://scan.mypinata.cloud/ipfs/bafybeih3olry3is4e4lzm7rus5l3h6zrphcal5a7ayfkhzm5oivjro2cp4/#/address/${process.env.REACT_APP_ELCOSA_CONTRACT_ADDRESS}`}
          data-testid="footer-giff-contract"
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();

            handleCopyClick(e, process.env.REACT_APP_EICOSA_CONTRACT_ADDRESS as string);
          }}
          title="footer-pgiff-address"
          rel="noreferrer noopener"
          className="flex items-center rounded-full bg-gray-200/20 px-2 py-0.5 text-sm font-medium text-gray-400 underline-offset-2 hover:underline"
          target="_blank"
        >
          elcosa Contract
          <span className="hidden md:block">: {process.env.REACT_APP_EICOSA_CONTRACT_ADDRESS}</span>
        </a>
        <a
          href={`https://scan.mypinata.cloud/ipfs/bafybeih3olry3is4e4lzm7rus5l3h6zrphcal5a7ayfkhzm5oivjro2cp4/#/address/${process.env.REACT_APP_ICOSA_CONTRACT_ADDRESS}`}
          data-testid="footer-pgiff-contract"
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();

            handleCopyClick(e, process.env.REACT_APP_ICOSA_CONTRACT_ADDRESS as string);
          }}
          title="footer-bnb-contract"
          rel="noreferrer noopener"
          className="flex items-center rounded-full bg-gray-200/20 px-2 py-0.5 text-sm font-medium text-gray-400 underline-offset-2 hover:underline"
          target="_blank"
        >
          Icosa Contract
          <span className="hidden md:block">: {process.env.REACT_APP_ICOSA_CONTRACT_ADDRESS}</span>
        </a>
        <a
          href={`https://scan.mypinata.cloud/ipfs/bafybeih3olry3is4e4lzm7rus5l3h6zrphcal5a7ayfkhzm5oivjro2cp4/#/address/${process.env.REACT_APP_IBURN_CONTRACT_ADDRESS}`}
          data-testid="footer-pgiff-contract"
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();

            handleCopyClick(e, process.env.REACT_APP_IBURN_CONTRACT_ADDRESS as string);
          }}
          title="footer-bnb-contract"
          rel="noreferrer noopener"
          className="flex items-center rounded-full bg-gray-200/20 px-2 py-0.5 text-sm font-medium text-gray-400 underline-offset-2 hover:underline"
          target="_blank"
        >
          iBurn Contract
          <span className="hidden md:block">: {process.env.REACT_APP_IBURN_CONTRACT_ADDRESS}</span>
        </a>
        <div
          data-testid="footer-copyright"
          className="rounded-full bg-gray-200/20 px-2 py-0.5 text-sm font-medium text-gray-400"
        >
          Copyright &copy;{' '}
          {new Date(
            new Date().toLocaleString('en', { timeZone: 'America/New_York' })
          ).getFullYear()}
          , All Rights Reserved.
        </div>
        <a
          title="footer-disclamer"
          data-testid="footer-copyright-disclaimer"
          href={`https://ipfs.io/ipfs/${process.env.REACT_APP_DISCLAIMER_HASH}`}
          rel="noopener noreferrer"
          className="rounded-full bg-gray-200/20 px-2 py-0.5 text-sm font-medium text-gray-400 underline-offset-2 hover:underline"
          target="_blank"
        >
          Disclaimer
        </a>
      </div>
    </footer>
  );
};

export default Footer;
