import { toast } from 'react-toastify';
import { v4 } from 'uuid';
import { ethers } from 'ethers';

export const showNotification = (
  context: string,
  type?: 'success' | 'info' | 'warning' | 'error'
) =>
  type
    ? toast[type](context, {
        style: { fontFamily: "'Roboto', 'sans-serif'" }
      })
    : toast(context, {
        style: { fontFamily: "'Roboto', 'sans-serif'" }
      });

export const createNonce = (): string => ethers.solidityPackedKeccak256(['string'], [v4()]);
